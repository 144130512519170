import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import projects from './data/projects';
import articles from './data/articles';
import videos from './data/videos';

const Home = () => {

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Open modal with selected image
  const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };


  return (
    <div className="container">
       <div className="row">  
       <div className="col-sm">
            <h5>YouTube Videos..</h5>
            {videos.map((video, index) => (
                          <div key={index}>
                          <a href={video.link} target="_blank" rel="noopener noreferrer">
                            {video.title}
                          </a>
                          <hr id='v-links'></hr>
                        </div>

            ))}
          </div> 
          <div className="col-sm ">
            <section id='gallery'>
            <h5>Building...</h5>
              <hr></hr>
              
              {projects.map((project, index) => (
                <img 
                  key={index} 
                  src={project.image} 
                  alt={`Project ${index + 1}`} 
                  className="project-screenshot" 
                  onClick={() => openModal(project.image)}
                />
              ))}
            
            </section>
              
          </div>

          <div className="col-sm">
            <section id='articles'>
            <h5>Articles</h5>
            <ul>
              {articles.map((article, index) => (
                <li key={index}>
                  <p>{article.title}</p>
                  <p>{article.summary}</p>
                  <a href={article.link} target="_blank" rel="noopener noreferrer">Read more</a>
                  <hr></hr>
                </li>              
              ))}
            </ul>
            
            </section>
            
          </div>        
    </div>
    {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <img src={selectedImage} alt="Selected Project" className="full-image" />
          </div>
        </div>
      )}
  </div>
  );
}

export default Home;

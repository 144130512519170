const projects = [
    { image: 'car-dealership-project-image0.jpg' },
    { image: 'car-dealership-project-image1.jpg' },
    { image: 'car-dealership-project-image2.jpg' },
    { image: 'car-dealership-project-image3.png' },
    { image: 'car-dealership-project-image4.png' },
    { image: 'flag-quiz1.png' },
    { image: 'flag-quiz2.png' },
    { image: 'meme-club-black-bg.png' },
    { image: 'flag-quiz3.png' },
    { image: 'flag-quiz-json-frontend-footer.png' },
    { image: 'tee-tota-llier.png' },
    { image: 'wiki-memes-main.png' },
  ];
  export default projects;
  